<template>
  <q-form ref="editForm">
    <c-card title="LBLDETAIL" class="cardClassDetailForm">
      <template slot="card-button">
        <q-btn-group outline >
          <c-btn 
            v-show="editable" 
            :isSubmit="isSave"
            :url="saveUrl"
            :param="map"
            :mappingType="mappingType"
            label="LBLSAVEALL" 
            icon="save"
            @beforeAction="save"
            @btnCallback="saveCallback" />
        </q-btn-group>
      </template>
      <template slot="card-detail">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <div class="row">
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <c-text
                :editable="editable"
                :required="true"
                label="배치도면명"
                name="mapName"
                v-model="map.mapName">
              </c-text>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
              <c-plant
                :required="true"
                :editable="editable"
                type="edit" 
                name="plantCd" 
                v-model="map.plantCd" />
            </div>
            <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
              <c-text
                :editable="editable"
                :required="true"
                label="LBLSORTORDER"
                name="sortOrder"
                v-model="map.sortOrder">
              </c-text>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
              <c-radio 
                :editable="editable"
                :comboItems="useFlagItems"
                label="LBLUSEFLAG"
                name="useFlag" 
                v-model="map.useFlag">
              </c-radio>
            </div>
          </div>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
        </div>
      </template>
    </c-card>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
        <c-upload 
          style="margin-top:0 !important"
          :attachInfo="attachInfo"
          :editable="editable"
          :uploaderShow="uploaderShow"
          @files="setMap">
        </c-upload>
        <c-table
          ref="table"
          title="배치도면별 장소 목록"
          tableId="mapLocation"
          :editable="editable"
          :columns="grid.columns"
          :data="map.mapLocations"
          :gridHeight="grid.height"
          :filtering="false"
          :columnSetting="false"
          :usePaging="false"
          :isFullScreen="false"
          :isExcelDown="false"
          selection="multiple"
          rowKey="mdmLocationId"
        >
          <template slot="table-button">
            <q-btn-group outline >
              <c-btn v-if="editable" 
                icon="add" 
                @btnClicked="addLocation" />
              <c-btn v-if="editable&&map.mapLocations&&map.mapLocations.length > 0" 
                icon="remove" 
                @btnClicked="removeLocation" />
            </q-btn-group>
          </template>
        </c-table>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-9 col-lg-9 col-xl-9">
        <c-card title="배치도면" class="cardClassDetailForm" :noMarginPadding="true" :height="imgHeight"> 
          <template slot="card-detail">
            <div class="col-12">
              <div id="parent" ref="parent">
                <div v-if="map.mapLocations&&map.mapLocations.length>0&&setComplete"
                  :style="{'height': imgHeight}">
                  <VueDraggableResizable
                    v-for="(mp, idx) in map.mapLocations"
                    :key="idx"
                    ref="markImage"
                    class="my-class-map"
                    :parent="true"
                    :draggable="editable"
                    :resizable="editable"
                    class-name-dragging="my-dragging-class"
                    class-name-active="my-active-class"
                    :x="mp.x"
                    :y="mp.y"
                    :w="mp.w" :h="mp.h"
                    :grid="[5, 5]"
                    :minHeight="20" :minWidth="20"
                    @dragging="(x, y) => onDrag(mp, x, y)"
                    @resizing="(x, y, w, h) => onResize(mp, x, y, w, h)"
                  >
                  <q-tooltip anchor="top middle" self="center middle">{{mp.locationNamePath}}</q-tooltip>
                  </VueDraggableResizable>
                </div>
              </div>
            </div>
          </template>
        </c-card>
      </div>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </q-form>
</template>

<script>
import { uid } from 'quasar';
import VueDraggableResizable from 'vue-draggable-resizable'
import 'vue-draggable-resizable/dist/VueDraggableResizable.css'
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'work-map-detail',
  components: { VueDraggableResizable },
  props: {
    popupParam: {
      type: Object,
      default: function() {
        return {
          sopMapId: '',
        }
      },
    },
    contentHeight: {
      type: [String, Number],
      default: function() {
        return 'auto'
      },
    }
  },
  data() {
    return {
      map: {
        sopMapId: '',  // 지도 일련번호
        plantCd: '',  // 사업장 코드
        mapName: '',  // 지도명
        ratio: null,
        useFlag: '',  // 사용여부
        regUserId: '',  // 등록자 ID
        chgUserId: '',  // 수정자 ID
        sysAttachFileId: '',  // 수정자 ID
        contentType: '',  // 수정자 ID
        mapLocations: [], // 공정s
        deleteMapLocations: [], // 공정s
        sortOrder: 0,
      },
      grid: {
        columns: [
          {
            name: 'locationNamePath',
            field: 'locationNamePath',
            label: '장소',
            align: 'left',
            sortable: false,
          },
        ],
        height: '600px'
      },
      attachInfo: {
        isSubmit: '',
        taskClassCd: 'WORK_MAP',
        taskKey: '',
      },
      useFlagItems: [
        { code: 'Y', codeName: '사용' },
        { code: 'N', codeName: '미사용' },
      ],
      setComplete: false,
      mapSrc: require('@/assets/images/no-image.png'),
      imgHeight: '',
      uploaderShow: true,
      editable: true,
      detailUrl: '',
      saveUrl: '',
      isSave: false,
      mappingType: 'POST',
      popupOptions: {
        isFull: false,
        width: '60%',
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  watch: {
    contentHeight() {
      let height = (this.contentHeight - 390);
      if (height < 400) {
        height = 500;
      }
      this.grid.height = height + 'px'
      this.imgHeight = '810px'; //  String(this.contentHeight - 200) + 'px'
    }
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.detailUrl = selectConfig.sop.swp.map.get.url
      this.saveUrl = transactionConfig.sop.swp.map.save.url
      // code setting
      // list setting
      this.getDetail();
    },
    getDetail() {
      if (this.popupParam.sopMapId) {
        this.$http.url = this.$format(this.detailUrl, this.popupParam.sopMapId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.$_.extend(this.map, _result.data);
          this.$set(this.attachInfo, 'taskKey', this.map.sopMapId)
        },);
      }
    },
    setMap(files) {
      if (files && files.length > 0) {
        let data = files[0];
        this.$comm.previewImage({
          sysAttachFileId: data.sysAttachFileId,
          contentType: data.contentType,
        }).then(_result => {
          this.mapSrc = _result;
          this.setCanvas(5)
          this.uploaderShow = false;
        });
      } else {
        this.mapSrc = '';
        this.setCanvas(5)
        this.uploaderShow = true;
      }
    },
    setCanvas(size) {
      let image = new Image();
      image.src = this.mapSrc;
      let thisVue = this;
      image.onload = function () {
        let parent = document.getElementById('parent');
        thisVue.map.ratio = image.width / image.height
        let _width = thisVue.map.ratio * 800

        parent.style.cssText  = 'background: linear-gradient(-90deg, rgba(0, 0, 0, 0.05) 1px, transparent 1px) 0% 0% / ' 
          + size + 'px ' + size + 'px, linear-gradient(rgba(0, 0, 0, 0.05) 1px, transparent 1px) 0% 0% / ' + size + 'px ' + size + 'px '
          + ', url("' + thisVue.mapSrc + '") no-repeat left/contain;'
          + 'overflow:auto; transform-origin: left top;'
          + 'height:800px;width:' + _width + ';';

        setTimeout(() => {
          thisVue.setComplete = true;
        }, 300);
      };
    },
    /* eslint-disable no-unused-vars */
    addLocation() {
      if (!this.mapSrc) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: '지도 이미지를 업로드 후 위치정보를 추가바랍니다.',
          type: 'warning', // success / info / warning / error
        });
        return;
      }
      this.popupOptions.title = '위치정보 검색';
      this.popupOptions.param = {
        type: 'multiple',
        plantCd: this.map.plantcd,
      };
      this.popupOptions.target = () => import(`${'@/pages/mdm/lim/locationPop.vue'}`);
      this.popupOptions.width = '40%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeLocationPopup;
    },
    closeLocationPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        if (!this.map.mapLocations) this.map.mapLocations = [];
        this.$_.forEach(data, item => {
          if (this.$_.findIndex(this.map.mapLocations, { mdmLocationId: item.mdmLocationId }) === -1) {
            this.$http.url = selectConfig.mdm.location.path.url;
            this.$http.type = 'GET';
            this.$http.param = {
              mdmLocationId: item.mdmLocationId
            };
            this.$http.request((_result) => {
              let locationNamePath = '';
              if (_result.data && _result.data[0]) {
                locationNamePath = _result.data[0].locationName
              }
              this.map.mapLocations.push({ 
                sopmapLocationId: uid(),  // 지도별 공정 일련번호
                sopMapId: this.popupParam.sopMapId,  // 지도 일련번호
                mdmLocationId: item.mdmLocationId,  // 공정코드
                locationName: item.locationName,  // 장소명
                locationNamePath: locationNamePath,  // 장소명(경로포함)
                mapName: this.map.mapName,  // 지도명
                x: 0,
                y: 0,
                w: 100,
                h: 100,
                editFlag: 'C',
                regUserId: this.$store.getters.user.userId,  // 등록일
              });
            },);
          }
        })
      }
    },
    removeLocation() {
      let selectData = this.$refs['table'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: 'MSGNOITEMS', // 선택된 항목이 없습니다.
          type: 'warning', // success / info / warning / error
        });
      } else {
        this.$_.forEach(selectData, item => {
          if (this.$_.findIndex(this.map.deleteMapLocations, { mdmLocationId: item.mdmLocationId }) === -1
            && item.editFlag !== 'C') {
            this.map.deleteMapLocations.push(item)
          }
          this.map.mapLocations = this.$_.reject(this.map.mapLocations, item);
        })
        this.$refs['table'].$refs['compo-table'].clearSelection();
      }
    },
    onDrag(item, x, y) {
      this.$set(item, 'x', x)
      this.$set(item, 'y', y)
      if (item.editFlag !== 'C') {
        this.$set(item, 'editFlag', 'U')
        this.$set(item, 'chgUserId', this.$store.getters.user.userId)
      }
    },
    onResize(item, x, y, w, h) {
      this.$set(item, 'x', x)
      this.$set(item, 'y', y)
      this.$set(item, 'w', w)
      this.$set(item, 'h', h)
      if (item.editFlag !== 'C') {
        this.$set(item, 'editFlag', 'U')
        this.$set(item, 'chgUserId', this.$store.getters.user.userId)
      }
    },
    save() {
      if (this.popupParam.sopMapId) {
        this.mappingType = 'PUT'
      } else {
        this.mappingType = 'POST'
      }
      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: 'LBLCONFIRM',
            message: 'MSGSAVE', // 저장하시겠습니까?
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.map.regUserId = this.$store.getters.user.userId
              this.map.chgUserId = this.$store.getters.user.userId

              if (this.map.mapLocations && this.map.mapLocations.length > 0) {
                this.$_.forEach(this.map.mapLocations, item => {
                  this.$set(item, 'mapName', this.map.mapName)
                })
              }
              this.isSave = !this.isSave
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveCallback(result) {
      this.popupParam.sopMapId = result.data
      this.$set(this.attachInfo, 'taskKey', result.data)
      this.$set(this.attachInfo, 'isSubmit', uid())
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getDetail();
    },
  }
};
</script>
<style>
.my-class-map {
  /* background-color: #9E9E9E; */
  border: 2px dashed #FF5722;
  -webkit-transition: background-color 200ms linear;
  -ms-transition: background-color 200ms linear;
  transition: background-color 200ms linear;
  position: absolute;
  text-align: center;
}

.my-dragging-class {
  background-color: rgb(255, 87, 34, 0.7);
  border: 2px dashed #FF5722;
}
.my-active-class{
  background-color: rgb(255, 87, 34, 0.25);
  border: 2px dashed #FF5722;
}
</style>